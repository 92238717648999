var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"data-cy":"3955","fluid":""}},[_c('v-row',{attrs:{"data-cy":"3956"}},[_c('v-col',{staticClass:"col-md-4",attrs:{"data-cy":"3957"}},[_c('v-menu',{ref:"date_range_menu",attrs:{"data-cy":"3958","close-on-content-click":false,"transition":"scale-transition","min-width":"min-content","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('themis-input',{ref:"date_range_input",staticClass:"pb-4",attrs:{"data-cy":"3959","p-label":_vm.$t('1134'),"p-label-class":"secondary--text"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
return [_c('v-text-field',_vm._g({ref:"date_range_input_field",attrs:{"data-cy":"3960","color":"secondary","value":_vm.dateRangeText,"append-icon":"mdi-calendar-range","clear-icon":"mdi-close-circle-outline","background-color":"white","readonly":"","outlined":"","hide-details":"","dense":""},on:{"focus":onFocus,"blur":onBlur}},on))]}}],null,true)})]}}]),model:{value:(_vm.showDateRangeMenu),callback:function ($$v) {_vm.showDateRangeMenu=$$v},expression:"showDateRangeMenu"}},[_c('v-date-picker',{ref:"date_picker_field",attrs:{"data-cy":"3961","width":"290","range":""},on:{"input":_vm.handleDateInput},model:{value:(_vm.selectedDates),callback:function ($$v) {_vm.selectedDates=$$v},expression:"selectedDates"}})],1)],1)],1),_c('v-row',{attrs:{"data-cy":"3962"}},_vm._l((_vm.kpisToDisplay),function(item,index){return _c('v-col',{key:index,staticClass:"col-md-3",attrs:{"data-cy":"3963"}},[_c('v-card',{ref:("kpi_card_" + index),refInFor:true,staticClass:"pa-4",attrs:{"data-cy":("3964_" + index),"width":"240"}},[_c('span',{ref:("kpi_title_" + index),refInFor:true,staticClass:"info--text text--lighten-1",attrs:{"data-cy":("3965_" + index)}},[_vm._v(_vm._s(item.kpiTitle))]),(item.kpiTooltipText)?_c('v-tooltip',{ref:("tooltip_for_kpi_card_" + index),refInFor:true,attrs:{"data-cy":"3969","color":"grey darken-2","max-width":"400","nudge-right":"100","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({ref:("information_icon_for_tooltip_" + index),refInFor:true,staticClass:"ml-2",attrs:{"data-cy":"3970","color":"info lighten-1","medium":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',{attrs:{"data-cy":"3976"}},[_vm._v(_vm._s(item.kpiTooltipText))])]):_vm._e(),_c('v-row',{attrs:{"data-cy":"3966"}},[_c('v-col',{attrs:{"data-cy":"3967"}},[_c('div',{staticClass:"d-block",attrs:{"data-cy":"3968"}},[_c('span',{ref:("value_to_display_" + index),refInFor:true,staticClass:"text-h6 grey--text text--darken-4",attrs:{"data-cy":("3971_" + index)}},[_vm._v(_vm._s(item.value))])]),_c('div',{staticClass:"d-block",attrs:{"data-cy":"3972"}},[_c('span',{ref:("difference_for_selected_date_" + index),refInFor:true,staticClass:"body-2",class:item.class,attrs:{"data-cy":("3973_" + index)}},[_vm._v(_vm._s(item.difference))])]),_c('div',{staticClass:"d-block",attrs:{"data-cy":"3974"}},[_c('span',{ref:("vs_previous_period_text_" + index),refInFor:true,staticClass:"info--text text--lighten-1",attrs:{"data-cy":("3975_" + index)}},[_vm._v(_vm._s(_vm.$t('1142')))])])])],1)],1)],1)}),1),(_vm.chartData)?[_c('v-row',{attrs:{"data-cy":"4000"}},_vm._l((_vm.chartData),function(chart){return _c('v-col',{key:chart.vColDataCy,staticClass:"col-md-6",attrs:{"data-cy":chart.vColDataCy}},[_c('v-card',{ref:chart.vCardRef,refInFor:true,staticStyle:{"z-index":"0"},attrs:{"data-cy":chart.vCardDataCy}},[_c('v-card-title',{ref:chart.vCardTitleRef,refInFor:true,attrs:{"data-cy":chart.vCardTitleDataCy}},[_vm._v(_vm._s(_vm.$t(chart.cardTitle)))]),_c('charts',{attrs:{"data-cy":chart.chartsDataCy,"options":chart.options,"series":chart.series,"type":"bar","height":chart.height}})],1)],1)}),1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }