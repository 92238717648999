import { render, staticRenderFns } from "./template.pug?vue&type=template&id=2f24a11e&lang=pug&external"
import script from "./script.js?vue&type=script&lang=js&external"
export * from "./script.js?vue&type=script&lang=js&external"
import style0 from "./style.sass?vue&type=style&index=0&prod&lang=sass&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
installComponents(component, {VBtn,VCard,VCardActions,VCol,VDatePicker,VDivider,VIcon,VRow,VTab,VTabItem,VTabs,VTabsItems,VTimePicker})
